import './src/index.css';
import React from 'react';
import Providers from './src/components/Providers';

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>;
};

export const onRouteUpdate = ({ location }) => {
  const win = window as any;

  const SHOULD_TRACK =
    process.env.NODE_ENV === 'production' &&
    window.location.host === 'www.taitounited.fi';

  if (SHOULD_TRACK) {
    if (win.ga) {
      win.ga('set', 'page', location.pathname);
      win.ga('send', 'pageview');
    }
    if (win.fbq) {
      win.fbq('track', 'PageView');
    }
  }
};
