import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { Typography } from '../../theme';
import { media } from '../../utils/styled';
import Heading from './Heading';
import Stack from './Stack';

const Section: React.FC<{
  title: string;
  titleVariant?: Typography;
  graphic?: ReactNode;
  reversed?: boolean;
  id?: string;
  children?: ReactNode;
}> = ({
  title,
  titleVariant = 'title-2',
  graphic,
  reversed = false,
  id,
  children,
}) => {
  const content = (
    <Stack
      spacing="large"
      axis="y"
      style={{
        flex: 3,
      }}
    >
      <Heading
        variant={titleVariant}
        blockColor={reversed ? 'yellow' : 'primary-dark'}
      >
        {title}
      </Heading>

      {children}
    </Stack>
  );

  return (
    <Stack
      id={id}
      axis={{ _: 'x', mdDown: 'y' }}
      justify="space-between"
      spacing={{ _: 'xxxlarge', mdDown: 'large' }}
      reverse={{ _: reversed, mdDown: false }}
    >
      <>
        {content}

        {graphic && <Graphic>{graphic}</Graphic>}
      </>
    </Stack>
  );
};

const Graphic = styled.div`
  width: 100%;
  flex: 2;

  ${media.mdDown`
    max-width: 500px;
  `}
`;

export default Section;
