import React from 'react';
import styled from '@emotion/styled';

type CompoundComponent = {
  Filter: typeof DuotoneFilter;
};

const Duotone: React.FC & CompoundComponent = ({ children, ...rest }) => (
  <Wrapper {...rest}>
    <InsetWrapper>
      {children}
      <DuotoneFilter />
    </InsetWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  z-index: 1;
`;

const DuotoneFilter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
  background-color: transparent;
  mix-blend-mode: color;
  transition: background-color 0.15s;

  &:hover {
    background-color: ${p => p.theme.colors['primary-dark']};
  }
`;

const InsetWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

// Expose the filter styled component so that duotone effect can be triggered
// when eg. the parent component is hovered:
// --> &:hover ${Duotone.Filter} {...}
Duotone.Filter = DuotoneFilter;

export default Duotone;
