// @ts-nocheck
/* eslint-disable */

// NOTE: this has to be a function since window is not defined during SSR
const isProd = () => window.location.host === 'www.taitounited.fi';

export function setupGA() {
  try {
    const gaScript = document.createElement('script');
    gaScript.async = true;
    gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-5B7D7XC67N';

    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(gaScript, firstScript);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    if (isProd()) gtag('config', 'G-5B7D7XC67N');
  } catch (error) {
    console.log('Analytics tracking error', error);
  }
}

// https://developers.facebook.com/docs/facebook-pixel/implementation/
export function setupFbPixel() {
  try {
    /* prettier-ignore */
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');

    fbq('init', '1432631823425494');
    if (isProd()) fbq('track', 'PageView');
  } catch (error) {
    console.log('FB Pixel tracking error', error);
  }
}

export function setupAdPixel() {
  try {
    const img = document.getElementById('adnxs-img') as HTMLImageElement;
    if (isProd() && img) {
      img.src = 'https://secure.adnxs.com/seg?member_id=7458&add_code=185522A&t=2'; // prettier-ignore
    }
  } catch (error) {
    console.log('Ad Pixel tracking error', error);
  }
}
