import styled from '@emotion/styled';
import { Color } from '../../theme';

interface AccentBlockProps {
  color?: Color;
}

const AccentBlock = styled.div<AccentBlockProps>`
  width: 60px;
  height: 6px;
  background-color: ${p => p.theme.colors[p.color || 'primary-dark']};
`;

export default AccentBlock;
