import React from 'react';
import { BROWSER_SUPPORT } from '../constants';

// https://usehooks.com/useOnScreen/
export function useOnScreen(
  ref: React.MutableRefObject<any>,
  rootMargin = '0px'
) {
  const [isIntersecting, setIntersecting] = React.useState(false);

  React.useEffect(() => {
    if (!BROWSER_SUPPORT.IntersectionObserver) {
      return;
    }

    const el = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin }
    );

    if (el) {
      observer.observe(el);
    }

    return () => {
      observer.unobserve(el);
    };
  }, [ref, rootMargin]);

  return isIntersecting;
}

export function useMount() {
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted;
}
