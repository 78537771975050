import React, { ReactNode } from 'react';
import { ThemeProvider } from '@emotion/react';

import theme from '../theme';
import { CookieConsentProvider } from './CookieConsent';
import { AriaLiveContextProvider } from './common';

const Providers: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CookieConsentProvider>
        <AriaLiveContextProvider>{children}</AriaLiveContextProvider>
      </CookieConsentProvider>
    </ThemeProvider>
  );
};

export default Providers;
