import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import { Color } from '../../theme';
import DecorationMix1 from '../../images/svg/decoration-mix-1.inline.svg';
import DecorationMix2 from '../../images/svg/decoration-mix-2.inline.svg';
import DecorationMix3 from '../../images/svg/decoration-mix-3.inline.svg';
import DecorationTriangle from '../../images/svg/decoration-triangle.inline.svg';

type Props = {
  size?: number;
  color?: Color;
  children?: ReactNode;
};

const Triangle = ({ size = 140, color }: Props) => (
  <DecorativeShapeWrapper
    xmlns="http://www.w3.org/2000/svg"
    width={size * (111.571 / 141.288)}
    height={size}
    viewBox="0 0 111.571 141.288"
  >
    <Path
      d="M64,0l64,60H0Z"
      transform="matrix(0.454, -0.891, 0.891, 0.454, 0, 114.049)"
      fill={color}
    />
  </DecorativeShapeWrapper>
);

const Triangle2 = ({ size = 90, color }: Props) => (
  <DecorativeShapeWrapper
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 77.585 73.943"
  >
    <Path
      d="M32.5,0,65,60H0Z"
      transform="translate(14.515) rotate(14)"
      fill={color}
    />
  </DecorativeShapeWrapper>
);

const Circle = ({ size = 80, color }: Props) => (
  <DecorativeShapeWrapper
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 100 100"
  >
    <CircleSvg cx="50" cy="50" r="50" fill={color} />
  </DecorativeShapeWrapper>
);

const Square = ({ size = 80, color }: Props) => (
  <DecorativeShapeWrapper
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 75.621 75.621"
  >
    <Rect
      width="59"
      height="59"
      transform="translate(0 20.179) rotate(-20)"
      fill={color}
    />
  </DecorativeShapeWrapper>
);

const Frames: React.FC<{ children?: ReactNode }> = ({ children }) => (
  <DecorativeFramesWrapper>
    <TopCorner>
      <DecorationMix1 width="200" />
    </TopCorner>

    <BottomCorner>
      <DecorationTriangle />
    </BottomCorner>

    {children}
  </DecorativeFramesWrapper>
);

const Frames2: React.FC<{ children?: ReactNode }> = ({ children }) => (
  <DecorativeFramesWrapper>
    <TopCorner>
      <DecorationMix2 width="250" />
    </TopCorner>

    <BottomCorner>
      <DecorationMix3 />
    </BottomCorner>

    {children}
  </DecorativeFramesWrapper>
);

const DecorativeShapeWrapper = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-60%, -55%);
  z-index: -1;
`;

const DecorativeFramesWrapper = styled.div`
  position: relative;
  padding: ${(p) => p.theme.spacing.xxlarge} ${(p) => p.theme.spacing.medium};
  z-index: -1;
`;

const CircleSvg = styled.circle<{ fill?: Color }>`
  fill: ${(p) => p.theme.colors[p.fill || 'primary-light']};
`;

const Rect = styled.rect<{ fill?: Color }>`
  fill: ${(p) => p.theme.colors[p.fill || 'primary-light']};
`;

const Path = styled.path<{ fill?: Color }>`
  fill: ${(p) => p.theme.colors[p.fill || 'primary-light']};
`;

const Decoration = styled.div`
  position: absolute;
  width: 80%;
  z-index: -1;
`;

const TopCorner = styled(Decoration)`
  bottom: 100%;
  right: 0;
  transform: translate(5vw, 150px);
`;

const BottomCorner = styled(Decoration)`
  bottom: 0;
  left: 0;
  transform: translateX(-10%);
`;

const Shapes = {
  Triangle,
  Triangle2,
  Circle,
  Square,
  Frames,
  Frames2,
};

export default Shapes;
