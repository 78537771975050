import React from 'react';
import styled from '@emotion/styled';

import MagnifyingGlassIcon from '../../images/svg/magnifying-glass.inline.svg';
import { media } from '../../utils/styled';

type Props = {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  collapse?: boolean;
  setCollapse?: (value: boolean) => void;
};

const SearchField: React.FC<Props> = ({
  value,
  setValue,
  placeholder,
  collapse = false,
  setCollapse,
  ...rest
}) => {
  const expand = () => {
    if (collapse && setCollapse) {
      setCollapse(false);
    }
  };

  return (
    <Container>
      <SearchFieldWrapper collapse={collapse}>
        <Input
          {...rest}
          id="search-input"
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
          placeholder={placeholder}
          maxLength={20}
        />

        <MagnifyingGlass onClick={expand} />
      </SearchFieldWrapper>
    </Container>
  );
};

const Container = styled.div`
  flex: 0;
  display: flex;
  align-items: center;

  ${media.sm`
    width: 100%;
  `}
`;

const SearchFieldWrapper = styled.label<{
  collapse: boolean;
}>`
  box-sizing: content-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${(p) => (p.collapse ? 14 : 180)}px;
  width: ${(p) => (p.collapse ? 0 : 100)}%;
  padding: ${(p) => p.theme.spacing.small} ${(p) => p.theme.spacing.default};
  border: solid 3px ${(p) => p.theme.colors['grey-light']};
  border-radius: 999px;
  background-color: ${(p) => p.theme.colors['grey-light']};
  transition: background-color 0.15s, width 0.6s, min-width 0.6s;
  overflow: hidden;

  ${media.sm`
    width: 100%;
  `}

  &:focus-within {
    ${(p) => p.theme.focus}
    background-color: ${(p) => p.theme.colors.white};

    & > input {
      box-shadow: none;
    }
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0;
  border: none;
  background-color: transparent;
  ${(p) => p.theme.typography['body-secondary']}
  color: ${(p) => p.theme.colors['grey-dark']};
  font-weight: 600;
`;

const MagnifyingGlass = styled(MagnifyingGlassIcon)`
  flex: none;
  width: 24px;
  cursor: pointer;
`;

export default SearchField;
