import React, { CSSProperties } from 'react';

import { Typography, Color } from '../../theme';
import Text from './Text';
import AccentBlock from './AccentBlock';
import Stack from './Stack';
import { WithResponsiveProps } from '../../utils/styled';

type HeadingProps = {
  children: any;
  variant?: Typography;
  blockColor?: Color | 'none';
  id?: string;
} & WithResponsiveProps<{ align?: CSSProperties['alignItems'] }>;

const Heading = ({
  children,
  id,
  variant = 'title-2',
  blockColor,
  align,
}: HeadingProps) => (
  <Stack id={id} spacing="small" align={align || 'inherit'}>
    <Text variant={variant}>{children}</Text>
    {blockColor !== 'none' && <AccentBlock color={blockColor} />}
  </Stack>
);

export default Heading;
