import React, { ReactNode } from 'react';
import Cookies from 'js-cookie';

import { setupGA, setupFbPixel, setupAdPixel } from '../../utils/tracking';

type CookieConsentValue = {
  isVisible: boolean;
  show: () => void;
  hide: () => void;
  accept: () => void;
  decline: () => void;
  onInitDone: () => void;
};

const CookieConsentContext = React.createContext<
  CookieConsentValue | undefined
>(undefined);

const CookieConsentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [initialized, setInitialized] = React.useState(false);
  const [isVisible, setVisible] = React.useState(false);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const onInitDone = () => setInitialized(true);

  const onAction = () => {
    // Reload the page if consent was updated in order to reflect the changes in script loading
    // if (initialized) window.location.reload();

    // Otherwise mark as initialized and hide the banner
    onInitDone();
    hide();
  };

  const accept = () => {
    acceptAllCookies();
    onAction();
  };

  const decline = () => {
    declineAllCookies();
    onAction();
  };

  const contextValue = { isVisible, show, hide, accept, decline, onInitDone };

  return (
    <CookieConsentContext.Provider value={contextValue}>
      {children}
    </CookieConsentContext.Provider>
  );
};

// Utilities ------------------------------------------------------------------

const COOKIES = ['ga', 'fb', 'adnxs'] as const;

const scriptHandlers = {
  ga: setupGA,
  fb: setupFbPixel,
  adnxs: setupAdPixel,
};

function getAllCookies() {
  return COOKIES.map(key => ({ key, consent: Cookies.get(`${key}-consent`) }));
}

function acceptAllCookies() {
  COOKIES.forEach(key => {
    Cookies.set(`${key}-consent`, 'true');
    scriptHandlers[key]();
  });
}

function declineAllCookies() {
  COOKIES.forEach(key => {
    Cookies.set(`${key}-consent`, 'false');
  });
}

// Hooks ----------------------------------------------------------------------

export const useCookieConsent = () => {
  const value = React.useContext(CookieConsentContext);
  if (value === undefined) {
    throw new Error(
      'useCookieConsent must be used within a CookieConsentProvider'
    );
  }
  return value;
};

export const useCookieConsentEffect = () => {
  const cookieConsent = useCookieConsent();

  React.useEffect(() => {
    const cookies = getAllCookies();

    // If any of the cookies is not either "true" or "false"
    // we need to prompt the cookie consent banner
    const shouldPromptForConsent = cookies.some(
      c => c.consent !== 'true' && c.consent !== 'false'
    );

    if (shouldPromptForConsent) {
      cookieConsent.show();
    } else {
      // Execute scripts that the user has given consent to
      cookies.forEach(({ key, consent }) => {
        if (consent === 'true') scriptHandlers[key]();
      });

      cookieConsent.onInitDone();
    }
  }, []); // eslint-disable-line
};

export default CookieConsentProvider;
