import { media } from './utils/styled';
// TODO: add responsive font sizes to typography

const theme = {
  colors: {
    'primary-light': '#DEF2E7',
    'primary-light-muted': '#EFF9F3',
    'primary-dark': '#008C41',
    'primary-dark-muted': '#009A48',
    'primary-opaque': '#009A4844',
    'secondary-dark': '#008a40',
    yellow: '#FFDA60',
    black: '#2C2C2C',
    white: '#FFFFFF',
    // TODO: update grey colors
    grey: '#C6CACC',
    'grey-light': '#EFF0F2',
    'grey-light-muted': '#E7E8EA',
    'grey-medium': '#ACACAC',
    'grey-dark': '#707070',
    red: '#EB4034',
    'focus-outline': '#003CFF',
  },
  spacing: {
    none: '0px',
    xxsmall: '2px',
    xsmall: '4px',
    small: '8px',
    default: '16px',
    medium: '24px',
    large: '32px',
    xlarge: '48px',
    xxlarge: '64px',
    xxxlarge: '104px',
  },
  fonts: {
    primary: `
      font-family: 'Open Sans', sans-serif;
    `,
    secondary: `
      font-family: 'attribute-mono', sans-serif;
    `,
  },
  focus: `
    box-shadow: 0 0 0 2px white, 0 0 0 5px #003cff;
  `,
  typography: {
    'title-1': `
      font-family: 'attribute-mono', sans-serif;
      font-size: 72px;
      font-weight: 700;
      line-height: 1.2;

      ${media.lg`
        font-size: 60px;
      `}

      ${media.md`
        font-size: 48px;
      `}

      ${media.sm`
        font-size: 32px;
      `}
    `,
    'title-2': `
      font-family: 'attribute-mono', sans-serif;
      font-size: 36px;
      font-weight: 700;
      line-height: 1.4;

      ${media.md`
        font-size: 32px;
      `}

      ${media.sm`
        font-size: 28px;
      `}
    `,
    'title-3': `
      font-family: 'attribute-mono', sans-serif;
      font-style: italic;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.5;

      ${media.md`
        font-size: 20px;
      `}

      ${media.sm`
        font-size: 18px;
      `}
    `,
    subtitle: `
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.6;

      ${media.sm`
        font-size: 16px;
      `}
    `,
    'subtitle-small': `
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.3;
    `,
    'body-primary': `
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      line-height: 1.8;
    `,
    'body-secondary': `
      font-family: 'attribute-mono', sans-serif;
      font-size: 16px;
      line-height: 1.8;
    `,
    'body-primary-small': `
      font-family: 'Open sans', sans-serif;
      font-size: 12px;
      line-height: 2;
    `,
    'body-secondary-big': `
      font-family: 'attribute-mono', sans-serif;
      font-style: italic;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
    `,
    caption: `
      font-family: 'attribute-mono', sans-serif;
      font-size: 14px;
      line-height: 1.4;
      text-transform: uppercase;
    `,
    action: `
      font-family: 'attribute-mono', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.4;
    `,
  },
};

export type Theme = typeof theme;

export type Color = keyof Theme['colors'];

export type SpacingUnit = keyof Theme['spacing'];

export type Typography = keyof Theme['typography'];

/* 
You can test and configure maps look at
https://mapstyle.withgoogle.com and
the site will give a valid json to copy here
*/
export const googleMapsTheme = [
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#daf1dc',
      },
    ],
  },
  {
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#9bd9a8',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fdf6b4',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ebfffc',
      },
    ],
  },
];

export default theme;
