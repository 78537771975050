import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { FiChevronDown as Arrow } from 'react-icons/fi';
import Text from './Text';
import Link from './Link';
import Stack from './Stack';
import { media } from '../../utils/styled';

type ArrowDirection = 'right' | 'up' | 'down' | 'left';

interface Props {
  to: string;
  arrowDirection?: ArrowDirection;
  children?: ReactNode;
}

const rotationForDirection: { [dir in ArrowDirection]: string } = {
  right: '-90deg',
  up: '180deg',
  down: '0deg',
  left: '90deg',
};

const ArrowLink: React.FC<Props> = ({
  to,
  arrowDirection = 'down',
  children,
  ...rest
}) => {
  const rotation = `rotate(${rotationForDirection[arrowDirection]})`;

  return (
    <LinkWrapper {...rest} to={to} color="black">
      <ActionStack axis="x" align="center">
        <Text variant="action">{children}</Text>
        <Arrow width={32} height={32} style={{ transform: rotation }} />
      </ActionStack>
    </LinkWrapper>
  );
};

export const ArrowButton: React.FC<
  {
    arrowDirection?: ArrowDirection;
  } & React.HTMLProps<HTMLButtonElement>
> = ({ children, arrowDirection = 'right', ...rest }) => {
  const rotation = `rotate(${rotationForDirection[arrowDirection]})`;
  const reverse = arrowDirection === 'left';

  return (
    <ArrowButtonWrapper {...(rest as any)} type="button" reverse={reverse}>
      <ActionStack axis="x" align="center" reverse={reverse}>
        <Text variant="action" color="black">
          {children}
        </Text>
        <Arrow size={24} style={{ transform: rotation }} color="black" />
      </ActionStack>
    </ArrowButtonWrapper>
  );
};

const commonCSS = (p: any) => css`
  position: relative;
  z-index: 0;

  &::after {
    position: absolute;
    z-index: -1;
    border-radius: 999px;
    top: -8px;
    bottom: -8px;
    left: ${p.reverse ? '-8px' : '-16px'};
    right: ${p.reverse ? '-16px' : '-8px'};
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:hover {
    span {
      border-color: ${p.theme.colors.black};
    }
  }

  &:focus {
    box-shadow: none;
    &:after {
      content: '';
      ${p.theme.focus};
    }
  }

  &:active {
    &::after {
      content: '';
      box-shadow: none;
    }
  }
`;

const LinkWrapper = styled(Link)<{ reverse?: boolean }>`
  text-decoration: none;
  ${(p) => commonCSS(p)}
`;

const ArrowButtonWrapper = styled.button<{ reverse: boolean }>`
  ${(p) => commonCSS(p)}

  ${media.xsm`
    svg {
      display: none;
    }
  `}
`;

const ActionStack = styled(Stack)<{ reverse?: boolean }>`
  span {
    border-bottom: 1px solid transparent;
    margin-bottom: -1px; /* Fix vertical aligment */
  }
`;

export default ArrowLink;
