import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { MdInfoOutline as InfoIcon } from 'react-icons/md';
import { VisuallyHidden } from '@reach/visually-hidden';
import { useI18next } from 'gatsby-plugin-react-i18next';
import iconHappyImg from '../../images/icon_happy.png';
import { Stack, Text, Link } from '../common';
import { useMount } from '../../utils/hooks';
import { media } from '../../utils/styled';
import {
  useCookieConsent,
  useCookieConsentEffect,
} from './CookieConsetProvider';

const CookieConsentBanner = () => {
  const cookieConsent = useCookieConsent();
  const isMounted = useMount();

  const { language } = useI18next();

  const messageEN =
    'We use cookies in order to improve the quality of the site.';
  const messageFI = 'Käytämme evästeitä sivuston laadun parantamiseksi.';

  const acceptEN = "Do you accept the site's analytics cookies?";
  const acceptFI = 'Hyväksytkö sivuston käyttämät analytiikkaevästeet?';

  useCookieConsentEffect();

  // Use the mounted state instead of checking eg. IS_BROWSER constant
  // https://joshwcomeau.com/react/the-perils-of-rehydration/
  if (!isMounted || !cookieConsent.isVisible) return null;

  return (
    <Wrapper
      axis={{ _: 'x', mdDown: 'y' }}
      align="center"
      justify="space-between"
      spacing="large"
    >
      <Stack axis="x" align="center">
        <IconImg src={iconHappyImg} alt="Iloinen taitohahmo" />

        <BubbleStack spacing={{ _: 'none', sm: 'small' }}>
          <Text variant="body-primary">
            {language === 'en' ? messageEN : messageFI}
          </Text>
          <Text variant="body-primary-small">
            {language === 'en' ? acceptEN : acceptFI}
          </Text>
        </BubbleStack>
      </Stack>

      <ActionStack align="center" reverse={true} axis={{ _: 'x', mdDown: 'y' }}>
        <Link to="/assets/rekisteriseloste.pdf" color="primary-dark">
          <Stack axis="x" spacing="xsmall" align="center">
            <InfoIcon size={16} />
            <Text variant="body-primary-small" as="span">
              {language === 'en' ? 'Privacy Policy' : 'Tietosuojatiedot'}
            </Text>
          </Stack>
        </Link>

        <ActionStack axis="x" spacing={{ _: 'medium', sm: 'default' }}>
          <AcceptButton onClick={cookieConsent.accept}>
            {language === 'en' ? 'Accept' : 'Hyväksyn'}
            <VisuallyHidden>evästeet</VisuallyHidden>
          </AcceptButton>
        </ActionStack>
      </ActionStack>
    </Wrapper>
  );
};

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
`;

const Wrapper = styled(Stack)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.large};
  background-color: ${p => p.theme.colors.white};
  box-shadow: -2px 0px 24px rgba(0, 0, 0, 0.2);
  z-index: 999999;
  transform: translateY(100%);
  animation: ${slideIn} 300ms forwards cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-delay: 500ms;

  ${p => media.sm`
    padding: ${p.theme.spacing.medium} ${p.theme.spacing.default};
  `}
`;

const IconImg = styled.img`
  height: 54px;
  width: auto;
`;

const BubbleStack = styled(Stack)`
  background-color: ${p => p.theme.colors['grey-light']};
  padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.medium};
  border-radius: 8px;
  position: relative;

  &::before {
    content: ' ';
    right: 100%;
    bottom: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-right-color: ${p => p.theme.colors['grey-light']};
    border-width: 8px;
  }

  ${p => media.sm`
    padding: ${p.theme.spacing.default}
  `}
`;

const ActionStack = styled(Stack)`
  ${media.sm`
    width: 100%;
  `}
`;

const BaseButton = styled.button`
  border: none;
  padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.default};
  text-align: center;
  border-radius: 99px;
  transition: box-shadow 0.1s;
  ${p => p.theme.typography.action};

  &:hover,
  &:active {
    outline: none;
    box-shadow: 0 0 0 4px ${p => p.theme.colors['primary-opaque']};
  }
`;

const AcceptButton = styled(BaseButton)`
  background-color: ${p => p.theme.colors['primary-dark']};
  color: ${p => p.theme.colors.white};

  ${media.mdUp`
    min-width: 200px;
  `}

  ${media.sm`
    flex: 1;
  `}
`;

const DeclineButton = styled(BaseButton)`
  background-color: ${p => p.theme.colors['primary-light']};
  color: ${p => p.theme.colors['primary-dark']};
`;

export default CookieConsentBanner;
