import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import { css } from '@emotion/react';
import { media } from '../../utils/styled';

type Props = {
  label: string;
  selected: boolean;
  onClick: () => void;
  noBorder?: boolean;
  children?: ReactNode;
};

/** Inputs and labels need to be direct siblings instead of nested or else keyboard navigation
 * through different options is suboptimal
 */
const TogglableOption: React.FC<Props> = ({
  label,
  selected,
  noBorder,
  onClick,
  children,
  ...rest
}) => (
  <TogglableOptionLabel
    htmlFor={label.replace(' ', '-')}
    selected={selected}
    noBorder={noBorder}
    {...rest}
  >
    {children ?? label}
    <input
      className="visually-hidden"
      type="checkbox"
      name={label.replace(' ', '-')}
      id={label.replace(' ', '-')}
      checked={selected}
      onChange={onClick}
    />
  </TogglableOptionLabel>
);

const TogglableOptionLabel = styled.label<{
  selected: boolean;
  noBorder?: boolean;
}>`
  padding: ${(p) => p.theme.spacing.small} ${(p) => p.theme.spacing.medium};
  border-radius: 999px;
  border: solid 2px
    ${(p) => p.theme.colors[p.selected ? 'primary-dark' : 'black']};
  color: ${(p) => p.theme.colors[p.selected ? 'white' : 'black']};
  background-color: ${(p) =>
    p.theme.colors[p.selected ? 'primary-dark' : 'white']};
  ${(p) => p.theme.typography.caption};
  font-weight: 600;
  cursor: pointer;
  transition-duration: 0.15s;
  transition-property: background-color, color, box-shadow;
  white-space: nowrap;
  margin: 4px; /* for active/focus box-shadow */

  ${(p) =>
    p.noBorder &&
    css`
      border: 2px solid transparent;
      background-color: none;
      box-shadow: none;
      background: none;
      color: ${p.theme.colors.black};
    `}

  &:hover {
    border: solid 2px
      ${(p) => p.theme.colors[p.selected ? 'secondary-dark' : 'black']};
    color: ${(p) => p.theme.colors[p.selected ? 'white' : 'black']};
    background-color: ${(p) =>
      p.theme.colors[p.selected ? 'secondary-dark' : 'grey-light']};

    ${(p) => media.mdDown`
      background-color: ${
        p.theme.colors[p.selected ? 'primary-light' : 'white']
      };
    `}

    ${(p) =>
      p.noBorder &&
      css`
        border: 2px solid transparent;
        color: ${p.theme.colors['primary-dark']};
        background-color: none;
        box-shadow: none;
        background: none;
      `}
  }

  &:active {
    border-color: ${(p) => p.theme.colors['primary-dark']};
    color: ${(p) => p.theme.colors['primary-dark']};
    box-shadow: 0 0 0 4px ${(p) => p.theme.colors['primary-opaque']};
    ${(p) =>
      p.noBorder &&
      css`
        border: 2px solid transparent;
        background-color: none;
        box-shadow: none;
        background: none;
        color: ${p.theme.colors.black};
      `}
  }

  &:focus-within {
    ${(p) => p.theme.focus};
  }
`;

export default TogglableOption;
