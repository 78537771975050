import React from 'react';
import styled from '@emotion/styled';

import halfCircleSvg from '../../images/svg/half_circle.svg';

type Props = {
  size?: number;
};

const BubblePattern = ({ size = 70 }: Props) => (
  <BottomPattern
    size={size}
    style={{
      backgroundImage: `url("${halfCircleSvg}")`,
    }}
  />
);

const BottomPattern = styled.div<{ size: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${p => p.size / 2}px;
  background-size: ${p => p.size}px;
  background-repeat: repeat-x;
  background-position-x: center;
  z-index: 5;
`;

export default BubblePattern;
