import React, { ForwardedRef } from 'react';
import styled from '@emotion/styled';
import { useThrottle, usePreviousDistinct, useKey } from 'react-use';

import { BROWSER_SUPPORT } from '../../constants';
import { useOnScreen } from '../../utils/hooks';
import { getUrlParam, setUrlParam } from '../../utils/url';

type Props = {
  css?: any;
  initiallyVisible?: number;
  batchSize?: number;
  persistId?: string;
  children: React.ReactNode;
  as?: keyof JSX.IntrinsicElements;
};

const BATCHING_ENABLED = BROWSER_SUPPORT.IntersectionObserver;
const DEFAULT_VISIBLE_COUNT = 30;
const DEFAULT_BATCH_SIZE = 10;

const getPersistedCount = (id: string, fallback: number) =>
  Math.max(Number(getUrlParam(id)), fallback);

function FastGrid(
  {
    children,
    initiallyVisible = DEFAULT_VISIBLE_COUNT,
    batchSize = DEFAULT_BATCH_SIZE,
    persistId,
    ...rest
  }: Props,
  ref: ForwardedRef<HTMLDivElement>
) {
  const itemsCount = React.Children.count(children);
  const observeTargetRef = React.useRef<HTMLDivElement>(null);
  const renderMore = useOnScreen(observeTargetRef);
  const prevItemsCount = usePreviousDistinct(itemsCount);

  const [visibleCount, setVisibleCount] = React.useState(() => {
    let val = itemsCount;

    if (BATCHING_ENABLED) {
      val = persistId
        ? getPersistedCount(persistId, initiallyVisible)
        : initiallyVisible;
    }

    return val;
  });

  const visibleCountThrottled = useThrottle(visibleCount, 1000);

  // If children change reset the visible items back to initial value
  React.useEffect(() => {
    if (prevItemsCount !== itemsCount) {
      setVisibleCount(BATCHING_ENABLED ? initiallyVisible : itemsCount);
    }
  }, [prevItemsCount, itemsCount]); // eslint-disable-line

  // Render more in batches whenever intersection observer is triggered
  React.useEffect(() => {
    // Don't render if children list is empty
    if (renderMore && itemsCount) {
      setVisibleCount((prev) => Math.min(prev + batchSize, itemsCount));
    }
  }, [renderMore]); // eslint-disable-line

  // Persist visible item count to url in order to have proper scroll restoration on back navigation
  React.useEffect(() => {
    if (persistId) {
      setUrlParam(
        persistId,
        Math.max(visibleCountThrottled, DEFAULT_VISIBLE_COUNT)
      );
    }
  }, [persistId, visibleCountThrottled]);

  const handlePageScrollToEnd = React.useCallback(() => {
    setVisibleCount(itemsCount);
    requestAnimationFrame(() => window.scrollTo(0, document.body.scrollHeight));
  }, [itemsCount]);

  // Handle page end key usage -> scroll all the way to the end of the page immediately without lazy rendering
  useKey(
    (e) => e.key === 'End' || (e.metaKey && e.key === 'ArrowDown'),
    handlePageScrollToEnd
  );

  return (
    <div>
      <Wrapper {...rest} ref={ref} tabIndex={-1}>
        {React.Children.map(children, (node, index) =>
          index < visibleCount ? node : null
        )}
      </Wrapper>
      <ObserveTarget ref={observeTargetRef} />
    </div>
  );
}

export default React.forwardRef(FastGrid);

const Wrapper = styled.div`
  display: grid;
`;

const ObserveTarget = styled.div`
  pointer-events: none;
`;
