import React, { CSSProperties, ReactNode } from 'react';
import styled from '@emotion/styled';

import patternImg from '../../images/pattern.png';
import { SpacingUnit, Color } from '../../theme';
import { MAX_CONTENT_WIDTH, MAX_COMPACT_CONTENT_WIDTH } from '../../constants';
import Stack, { LayoutProps } from './Stack';
import { WithResponsiveProps, media } from '../../utils/styled';
import { css } from '@emotion/react';

type Props = {
  backgroundColor?: Color;
  verticalPadding?: SpacingUnit;
  compact?: boolean;
  backgroundPattern?: boolean;
  innerAs?: keyof JSX.IntrinsicElements;
  outerAs?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
  innerStyle?: CSSProperties;
} & WithResponsiveProps<LayoutProps>;

const Content: React.FC<Props> = ({
  children,
  compact = false,
  backgroundPattern = false,
  axis,
  spacing = 'xxlarge',
  align,
  justify,
  reverse,
  innerAs,
  outerAs,
  innerStyle,
  ...rest
}) => (
  <Wrapper {...rest} as={outerAs} backgroundPattern={backgroundPattern}>
    <ContentStack
      style={innerStyle}
      compact={compact}
      axis={axis}
      spacing={spacing}
      align={align}
      justify={justify}
      reverse={reverse}
      as={innerAs}
    >
      {children}
    </ContentStack>
  </Wrapper>
);

type WrapperProps = Pick<
  Props,
  'verticalPadding' | 'backgroundPattern' | 'backgroundColor'
> & {
  as?: Props['outerAs'];
};

const Wrapper = styled.section<WrapperProps>`
  position: relative;
  padding-left: ${(p) => p.theme.spacing.large};
  padding-right: ${(p) => p.theme.spacing.large};
  padding-top: ${(p) => p.theme.spacing[p.verticalPadding || 'xxxlarge']};
  padding-bottom: ${(p) => p.theme.spacing[p.verticalPadding || 'xxxlarge']};
  background-color: ${(p) =>
    p.backgroundColor ? p.theme.colors[p.backgroundColor] : 'transparent'};
  background-repeat: repeat;
  background-position-y: bottom;
  background-position-x: center;
  background-size: contain;

  ${(p) => media.sm`
    padding-left: ${p.theme.spacing.default};
    padding-right: ${p.theme.spacing.default};
    padding-top: ${p.theme.spacing.xlarge};
    padding-bottom: ${p.theme.spacing.xlarge};
  `}

  ${(p) =>
    p.backgroundPattern &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(${patternImg});
        opacity: 0.6;
        background-repeat: repeat;
        background-position-y: bottom;
        background-position-x: center;
        background-size: contain;
      }
    `}
`;

const ContentStack = styled(Stack, {
  shouldForwardProp: (p: string) => p !== 'compact',
})<{
  compact: boolean;
}>`
  position: relative;
  width: 100%;
  max-width: ${(p) =>
    p.compact ? MAX_COMPACT_CONTENT_WIDTH : MAX_CONTENT_WIDTH};
  margin-left: auto;
  margin-right: auto;
`;

export default Content;
