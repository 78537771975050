import React from 'react';
import styled from '@emotion/styled';
import { WindupChildren, Pace } from 'windups';
import { VisuallyHidden } from '@reach/visually-hidden';

import Text from './Text';
import { media } from '../../utils/styled';

interface Props {
  children: string;
}

const RevealedTitle: React.FC<Props> = ({ children }) => {
  const text = `${children}|`;
  const words = text.split(' ');

  return (
    <WrapperText variant="title-1" color="white">
      <AnimatedText aria-hidden="true">
        <WindupChildren>
          {words.map((word, i) => (
            <WordWrapper key={i} hide={false}>
              <Pace ms={50}>{word}</Pace>
            </WordWrapper>
          ))}
        </WindupChildren>
      </AnimatedText>

      {/* Reserve space for the text so that the content doesn't jump while animating the text */}
      <span aria-hidden="true">
        {words.map((word, i) => (
          <WordWrapper key={i} hide>
            {word}
          </WordWrapper>
        ))}
      </span>

      {/* Provide the text to screen readers without animation and the pipe character */}
      <VisuallyHidden>{children}</VisuallyHidden>
    </WrapperText>
  );
};

const WrapperText = styled(Text)``;

const AnimatedText = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const WordWrapper = styled.span<{ hide: boolean }>`
  display: inline-block;
  background-color: ${(p) => p.theme.colors['primary-dark']};
  padding: 4px 12px;
  opacity: ${(p) => (p.hide ? 0 : 1)};
  user-select: none;
`;

export default React.memo(RevealedTitle);
