import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { HiOutlineChevronRight as Arrow } from 'react-icons/hi';

import Text from './Text';
import Link from './Link';
import Stack from './Stack';
import { Color } from '../../theme';
import { media } from '../../utils/styled';

type ArrowDirection = 'right' | 'up' | 'down' | 'left';

type Props = {
  to: string;
  arrowDirection?: ArrowDirection;
  backgroundColor?: Color;
  children?: ReactNode;
  hideArrow?: boolean;
};

const rotationForDirection: { [dir in ArrowDirection]: string } = {
  right: '0deg',
  up: '-90deg',
  down: '90deg',
  left: '180deg',
};

const BorderedArrowLink: React.FC<Props> = ({
  to,
  arrowDirection = 'right',
  backgroundColor,
  children,
  hideArrow,
  ...rest
}) => {
  const rotation = `rotate(${rotationForDirection[arrowDirection]})`;

  return (
    <LinkWrapper {...rest} to={to} color="black">
      <ActionStack axis="x" align="center" backgroundColor={backgroundColor}>
        <Text variant="action">{children}</Text>
        {!hideArrow && <ArrowSvg size={22} style={{ transform: rotation }} />}
      </ActionStack>
    </LinkWrapper>
  );
};

export const BorderedArrowButton: React.FC<
  Omit<Props, 'to'> & React.HTMLProps<HTMLButtonElement>
> = ({
  arrowDirection = 'right',
  backgroundColor,
  children,
  disabled,
  hideArrow,
  ...rest
}) => {
  const rotation = `rotate(${rotationForDirection[arrowDirection]})`;

  return (
    <ButtonWrapper {...(rest as any)} type="button" disabled={disabled}>
      <ActionStack
        axis="x"
        align="center"
        backgroundColor={backgroundColor}
        reverse={arrowDirection === 'left'}
        disabled={disabled}
      >
        <Text variant="action">{children}</Text>
        {!hideArrow && <ArrowSvg size={22} style={{ transform: rotation }} />}
      </ActionStack>
    </ButtonWrapper>
  );
};

const ArrowSvg = styled(Arrow)`
  min-width: 22px;
  min-height: 22px;
`;

const commonCSS = css`
  border-radius: 999px;

  &:focus,
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    outline: none;
    box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
`;

const LinkWrapper = styled(Link)`
  text-decoration: none;
  ${commonCSS}

  ${media.xsm`
    svg {
      display: none;
    }
  `}
`;

const ButtonWrapper = styled.button`
  ${commonCSS}

  ${media.xsm`
    svg {
      display: none;
    }
  `}
`;

const ActionStack = styled(Stack, {
  shouldForwardProp: (p) => p !== 'backgroundColor',
})<{ backgroundColor?: Color; disabled?: boolean }>`
  border-radius: inherit;
  border: ${(p) =>
    p.backgroundColor === 'primary-dark'
      ? 'none'
      : `2px solid ${p.theme.colors[p.disabled ? 'grey-dark' : 'black']}`};
  padding: 12px ${(p) => p.theme.spacing.medium};
  ${(p) => p.disabled && `color: ${p.theme.colors['grey-dark']};`}
  background-color: ${(p) =>
    p.disabled
      ? p.theme.colors['grey-light']
      : p.backgroundColor
      ? p.theme.colors[p.backgroundColor]
      : 'transparent'};
  ${(p) =>
    p.backgroundColor === 'primary-dark' &&
    `color: ${p.theme.colors.white};
  `}

  &:hover {
    background-color: ${(p) =>
      p.backgroundColor === 'primary-dark'
        ? p.theme.colors['primary-dark-muted']
        : p.theme.colors['grey-light']};
  } ;
`;

export default BorderedArrowLink;
